import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import currency from 'currency.js';
import { ceil } from 'mathjs';

import { COMPENSATE_FEE_PERCENT_V3, OWN_PERCENT_V3 } from '../../../constants/base';
import { useCreateTBCPaymentMutation } from '../../../redux/api/payment';
import { RatingType, ReviewType } from '../../../redux/api/types/reviews';
import { useGetPaymentDataQuery } from '../../../redux/api/user';

import { Agreement } from './components/Agreement';
import { CardView } from './components/CardView';
import { Drawer } from './components/Drawer';
import { Feedback } from './components/Feedback';
import LogoBlock from './components/LogoBlock';
import { Pay2 } from './components/Pay2';
import { Rating } from './components/Rating';
import { Tips } from './components/Tips';
import UsersBlock from './components/UsersBlock';

export const Payment3 = () => {
  const [value, setValue] = useState('0₾');
  const [rating, setRating] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [mostValuable, setMostValuable] = useState('');
  const [isShown, setIsShown] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [activeUser, setActiveUser] = useState(0);
  const [compensate, setCompensate] = useState(true);
  const [terms, setTerms] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);

  const { t } = useTranslation();
  const { userId } = useParams();
  const { data } = useGetPaymentDataQuery(userId || '');
  const [createPayment, { data: paymentData, isLoading, isSuccess }] =
    useCreateTBCPaymentMutation();

  useEffect(() => {
    if (data) {
      setActiveUser(data?.activeUser);
      setCompensate(data.isComissionEnabled);
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      if (paymentData) {
        const link = document.createElement('a');
        link.href = paymentData?.transactionUrl || '';
        link.click();
      }
    }
  }, [isLoading, isSuccess]);

  const handleRatingClick = (index: number) => () => {
    setRating(index);
    setIsShown(true);
  };

  const handleValueableClose = (empty: boolean) => () => {
    if (empty) {
      setMostValuable('');
    }
    setIsShown(false);
  };
  const handleValeableClick = (next: string) => () => setMostValuable(next);

  const handlePaymentClick = (type: string) => () => {
    if (data) {
      createPayment({
        employeeId: activeUser,
        amount: +value.slice(0, -1),
        isCompensating: compensate,
        type,
        organizationId: data.organizationId,
        version: 3,
        review: {
          tips: +value.slice(0, -1),
          to: activeUser,
          rating: rating as RatingType,
          mostValuable: mostValuable as ReviewType,
          review: feedback,
        },
      });
    }
  };

  const handleUserClick = (id: number) => () => setActiveUser(id);
  const toggleCompensate = () => setCompensate(prev => !prev);
  const toggleTerms = () => setTerms(prev => !prev);
  const handleBack = () => setCurrentScreen(0);

  useEffect(() => {
    if (compensate) {
      setValue(prev => {
        const withComission = currency(prev.slice(0, -1), { precision: 10 })
          .divide(COMPENSATE_FEE_PERCENT_V3)
          .multiply(100);
        return `${ceil(withComission.value, 2)}₾`;
      });
    } else {
      setValue(prev => {
        const pureNumber = currency(prev.slice(0, -1), { precision: 10 });
        const withoutComission = pureNumber.subtract(pureNumber.multiply(OWN_PERCENT_V3));
        const toReturn = ceil(withoutComission.value - 0.01, 2);
        return `${toReturn < 0 ? 0 : toReturn}₾`;
      });
      setPercentage(0);
    }
  }, [compensate]);

  return (
    <>
      <div
        className={`${
          !currentScreen ? 'bg-phoneBG' : 'bg-white'
        } h-full py-[3.2vw] overflow-y-auto overflow-x-hidden"`}
      >
        {currentScreen === 0 ? (
          <div className="flex flex-col gap-[4.267vw]">
            <LogoBlock
              organization={data?.organization}
              ratingAvg={data?.ratingAvg}
              isRatingVisible={data?.isRatingVisible}
            />
            <UsersBlock handleUserClick={handleUserClick} activeUser={activeUser} data={data} />
            <Tips
              value={value}
              setValue={setValue}
              compensate={compensate}
              toggleCompensate={toggleCompensate}
              percentage={percentage}
              isError={isError}
              setIsError={setIsError}
              compensateCoeff={COMPENSATE_FEE_PERCENT_V3}
            />
            <div className="flex flex-col gap-[10px] py-[20px] px-[10px] bg-[#383838] rounded-[20px] mx-[4vw]">
              <p className="text-[#C1C2C3] text-[16px] leading-[20px] text-center px-[25px]">
                {t('pay.cashback1')}
              </p>
              <p className="text-white text-[20px] leading-[30px] text-center uppercase font-bold">
                <span>{t('pay.cashback2')}</span> <span className="text-phoneOrange">10%</span>{' '}
                <span>{t('pay.cashback3')}</span>
              </p>
            </div>
            <Rating rating={rating} handleRatingClick={handleRatingClick} />
            <Feedback feedback={feedback} setFeedback={setFeedback} />
            <Pay2
              clickHandler={handlePaymentClick}
              terms={[
                terms,
                +value.slice(0, -1) >= 1 && +value.slice(0, -1) <= 1000,
                !isError,
                !isLoading,
              ]}
              isLoading={isLoading}
            />
            <Agreement
              compensate={compensate}
              toggleCompensate={toggleCompensate}
              terms={terms}
              toggleTerms={toggleTerms}
              isDisabled={false}
            />
          </div>
        ) : (
          <CardView handleBack={handleBack} sendReview={() => {}} />
        )}
      </div>
      {isShown && (
        <div className="bg-mask h-full w-full absolute top-0 left-0 backdrop-blur-[5px]"></div>
      )}
      <Drawer
        isShown={isShown}
        handleValueableClose={handleValueableClose}
        handleValeableClick={handleValeableClick}
        mostValuable={mostValuable}
        rating={rating}
        markItems={data?.marks || []}
      />
    </>
  );
};
