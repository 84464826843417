import { createBrowserRouter, redirect } from 'react-router-dom';

import { ROUTES } from '../constants/routes';
import {
  AddEmployee,
  Analytics,
  AuthPage,
  ClientMenu,
  ConfirmRegistration,
  Landing,
  Menu,
  MenuCategory,
  NewRequest,
  Organizations,
  PasswordRestore,
  Payment,
  Payment2,
  PaymentEnd,
  Profile,
  QRCode,
  Rating,
  SendRestorePassword,
  Transactions,
  UserReview,
} from '../pages';
import Categories from '../pages/ClientMenu/Categories';
import MenuItems from '../pages/ClientMenu/MenuItems';
import { Payment3 } from '../pages/Payment/pay/index3';
import { checkToken } from '../utils/base';

export const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <></>,
    loader: () => {
      const isActive = checkToken();
      if (!isActive[0]) {
        /* eslint-disable-next-line */
        throw redirect(ROUTES.LOGIN);
      } else {
        /* eslint-disable-next-line */
        throw redirect(`${ROUTES.DASHBOARD}/${isActive[1]}${ROUTES.PROFILE}${ROUTES.PROFILE}`);
      }
    },
  },
  {
    path: ROUTES.REGISTER,
    element: <AuthPage type="register" />,
  },
  {
    path: ROUTES.LOGIN,
    element: <AuthPage type="login" />,
  },
  {
    path: ROUTES.USER_CONFIRM_REG,
    element: <ConfirmRegistration />,
  },
  {
    path: ROUTES.SEND_PASS_RESTORE,
    element: <SendRestorePassword />,
  },
  {
    path: ROUTES.PASS_RESTORE,
    element: <PasswordRestore />,
  },
  {
    path: ROUTES.REVIEW,
    element: <UserReview />,
  },
  {
    path: ROUTES.PROFILE_FULL,
    element: <Profile />,
  },
  {
    path: ROUTES.ANALYTICS_FULL,
    element: <Analytics />,
  },
  {
    path: ROUTES.RATING_FULL,
    element: <Rating />,
  },
  {
    path: ROUTES.QR_CODE_FULL,
    element: <QRCode />,
  },
  {
    path: ROUTES.ADD_EMPLOYEE_FULL,
    element: <AddEmployee />,
  },
  {
    path: ROUTES.ORGANIZATION_FULL,
    element: <Organizations />,
  },
  {
    path: ROUTES.NEW_REQUEST_FULL,
    element: <NewRequest />,
  },
  {
    path: ROUTES.PAYMENT_FULL,
    element: <Payment />,
  },
  {
    path: ROUTES.PAYMENT_FULL_V2,
    element: <Payment2 />,
  },
  {
    path: ROUTES.PAYMENT_FULL_V3,
    element: <Payment3 />,
  },
  {
    path: ROUTES.PAYMENT_END,
    element: <PaymentEnd />,
  },
  {
    path: ROUTES.LANDING,
    element: <Landing />,
  },
  {
    path: ROUTES.MENU_FULL,
    element: <Menu />,
  },
  {
    path: ROUTES.MENU_CATEGORY_FULL,
    element: <MenuCategory />,
  },
  {
    path: ROUTES.TRANSACTIONS,
    element: <Transactions />,
  },
  {
    path: ROUTES.MENU_CLIENT,
    element: <ClientMenu />,
  },
  {
    path: ROUTES.MENU_CLIENT_CATEGORY,
    element: <Categories />,
  },
  {
    path: ROUTES.MENU_CLIENT_MENU,
    element: <MenuItems />,
  },
]);
