export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  SEND_PASS_RESTORE: '/password/restore',
  PASS_RESTORE: '/password/:confirmId',
  PASSWORD: '/password',
  REVIEW: '/review',
  PROFILE: '/profile',
  USER_CONFIRM_REG: '/user/confirm/:confirmId',
  PROFILE_FULL: '/dashboard/:id/profile/:tab',
  ANALYTICS_FULL: '/dashboard/:id/analytics',
  DASHBOARD: '/dashboard',
  ANALYTICS: '/analytics',
  RATING: '/rating',
  RATING_FULL: '/dashboard/:id/rating',
  QR_CODE_FULL: '/dashboard/:id/qr',
  QR_CODE: '/qr',
  ADD_EMPLOYEE_FULL: '/dashboard/:id/rating/employee',
  ADD_EMPLOYEE: '/employee',
  SECURITY: '/security',
  ORGANIZATION_FULL: '/dashboard/:id/organizations',
  ORGANIZATION: '/organizations',
  NEW_REQUEST_FULL: '/dashboard/:id/new-requests',
  NEW_REQUEST: '/new-requests',
  PAYMENT_FULL: '/pay/:userId',
  PAYMENT_FULL_V2: '/v2/pay/:userId',
  PAYMENT_FULL_V3: '/v3/pay/:userId',
  LANDING: '/landing',
  USER_CONFIRM: '/user/confirm',
  PAYMENT_END: '/pay/thanks/:id',
  MENU: '/menu',
  CATEGORY: '/categories/',
  MENU_FULL: '/dashboard/:id/menu',
  MENU_CATEGORY_FULL: '/dashboard/:id/menu/:categoryId',
  TRANSACTIONS: '/dashboard/transactions',
  MENU_CLIENT: '/menu/:hash',
  MENU_CLIENT_CATEGORY: '/menu/:hash/categories',
  MENU_CLIENT_MENU: '/menu/:hash/categories/menu',
} as const;
