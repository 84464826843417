import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
import { ceil } from 'mathjs';

import { defaultTips } from '../config';

interface IProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  compensate: boolean;
  toggleCompensate: () => void;
  percentage: number;
  isError: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
  compensateCoeff: number;
}

export const Tips: FC<IProps> = ({
  value,
  setValue,
  compensate,
  setIsError,
  isError,
  compensateCoeff,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const handleFocus = () => {
    inputRef.current?.setSelectionRange(value.length - 1, value.length - 1);
  };

  const clearInput = () => setValue('0₾');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    // if (compensate) {
    //   toggleCompensate();
    // }
    const isDeleting = (e.nativeEvent as InputEvent).inputType === 'deleteContentBackward';
    const inputValue = e.target.value;

    let newValue = '';

    if (inputValue.includes('.')) {
      const ceilPart = inputValue.split('.')[0];
      if (isDeleting) {
        newValue = ceilPart;
      } else {
        const lastInputValue = inputValue.split('.')[1].slice(-2, -1);
        newValue = ceilPart + lastInputValue;
      }
    } else {
      newValue = inputValue.replace(/\D/g, '');
    }

    if (!newValue.length) {
      setValue('0₾');
    } else {
      if (newValue.startsWith('0')) {
        const sliced = +newValue.slice(1);
        if (sliced < 1) {
          setIsError(true);
        }
        if (compensate && !isDeleting) {
          const withComission = currency(newValue.slice(1), { precision: 10 })
            .divide(compensateCoeff)
            .multiply(100);
          setValue(`${ceil(withComission.value, 2)}₾`);
        } else {
          setValue(`${newValue.slice(1)}₾`);
        }
      } else {
        if (+newValue > 1000) {
          setIsError(true);
        }
        if (compensate && !isDeleting) {
          const withComission = currency(newValue, { precision: 10 })
            .divide(compensateCoeff)
            .multiply(100);
          setValue(`${ceil(withComission.value, 2)}₾`);
        } else {
          setValue(`${newValue}₾`);
        }
      }
    }
  };
  const setDefaultTips = (amount: number) => () => {
    if (compensate) {
      const withComission = currency(amount, { precision: 10 })
        .divide(compensateCoeff)
        .multiply(100);
      setValue(`${ceil(withComission.value, 2)}₾`);
    } else {
      setValue(`${amount}₾`);
    }
  };

  useEffect(() => {
    handleFocus();
  }, [value]);

  return (
    <div className="flex flex-col gap-[3.2vw] px-[4vw]">
      <div className="relative">
        <input
          className={`w-full bg-transparent border-b-[1px]
              border-phoneBorder focus:outline-none text-white pl-[7.2vw]
              font-medium text-[5.333vw] leading-[8vw] pb-[1.6vw]
            `}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onTouchStart={handleFocus}
          onClick={handleFocus}
          ref={inputRef}
        />
        {isError && <span className="text-[red]">{t('pay.amount_error')}</span>}
        <button onClick={clearInput} className="absolute top-[0.8vw] right-0">
          <img
            src="/images/icons/close_icon.svg"
            alt="close icon"
            className="w-[6.4vw] h-[6.4vw]"
          />
        </button>
      </div>
      <div className="flex gap-[1.6vw]">
        {defaultTips.map(el => (
          <button
            className={`w-[21.867vw] h-[13.333vw] text-black font-bold
                  text-[5.333vw] leading-[8vw] flex items-center justify-center
                  rounded-[3.2vw] ${el === +value.slice(0, -1) ? 'bg-mainYellow' : 'bg-white'}
                `}
            key={el}
            onClick={setDefaultTips(el)}
          >
            {el}₾
          </button>
        ))}
      </div>
    </div>
  );
};
