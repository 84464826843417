import { FC } from 'react';

import { ReactComponent as Arrow } from '../../static/arrow_down.svg';

import Button from './components/Button';
import { DOTS, usePagination } from './hooks/usePagination';

interface IProps {
  pages: number;
  onPageChange: (page: number) => void;
  page: number;
  setPage: (page: number) => void;
}

const Pagination: FC<IProps> = ({ pages, onPageChange, page, setPage }) => {
  const clickHandler = (nextPage: number | string) => () => {
    if (typeof nextPage === 'number') {
      setPage(nextPage);
      onPageChange(nextPage);
    }
  };

  const paginationRange = usePagination({
    totalCount: pages,
    siblingCount: 1,
    currentPage: page,
    pageSize: 8,
  });

  const lastPage = paginationRange![(paginationRange as (string | number)[]).length - 1];

  const handleNext = () => {
    if (page + 1 > +lastPage) {
      return;
    }
    setPage(page + 1);
    onPageChange(page + 1);
  };

  const handlePrev = () => {
    if (page - 1 <= 0) {
      return;
    }
    setPage(page - 1);
    onPageChange(page - 1);
  };

  return (
    <div className="flex gap-[18px] items-center">
      <button
        className={`${
          page === 1 ? 'text-textGray' : 'text-mainBlack'
        } flex gap-[5px] items-center text-[16px] leading-[24px]`}
        onClick={handlePrev}
      >
        <Arrow
          className={`${page === 1 ? '[&>path]:fill-textGray' : '[&>path]:fill-black '} rotate-90`}
        />
        Назад
      </button>
      {paginationRange!.map((pageNumber, i) => (
        <Button key={i} isActive={pageNumber === page} onClick={clickHandler(pageNumber)}>
          {pageNumber === DOTS ? pageNumber : pageNumber}
        </Button>
      ))}
      <button
        className={`${
          page === lastPage ? 'text-textGray' : 'text-mainBlack'
        } flex gap-[5px] items-center text-[16px] leading-[24px]`}
        onClick={handleNext}
      >
        Вперед
        <Arrow
          className={`${
            page === lastPage
              ? '[&>path]:fill-textGray text-textGray'
              : '[&>path]:fill-black text-mainBlack'
          } -rotate-90`}
        />
      </button>
    </div>
  );
};

export default Pagination;
