import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  clickHandler: (type: string) => () => void;
  terms: boolean[];
  isLoading: boolean;
}

export const Pay2: FC<IProps> = ({ clickHandler, terms, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col rounded-[3.2vw] mx-[4vw] border-[1px] border-btnBorder ${
        terms.some(e => !e) ? 'bg-lightGray' : 'bg-phoneOrange'
      }`}
    >
      <button
        className={`btn no-animation hover:bg-white bg-white w-full border-0 border-b-[1px]
          border-b-btnBorder font-bold text-[4.267vw] leading-[6.4vw] text-mainBlack
          flex items-center gap-[3.2vw] normal-case disabled:grayscale disabled:bg-lightGray
          disabled:border-b-[1px] disabled:border-b-lowGray rounded-b-none
        `}
        onClick={clickHandler('apple')}
        disabled={terms.some(e => !e) || isLoading}
      >
        {t('pay.payService')}
        <div className="flex relative">
          <img
            src="/images/icons/apple-pay.svg"
            alt="apple-pay icon"
            className="w-[12vw] h-[8vw]"
          />
          {terms.some(e => !e) && (
            <div className="absolute top-0 left-0 w-full h-full bg-overlay"></div>
          )}
        </div>
      </button>
      <button
        className={`btn no-animation hover:bg-white bg-white w-full border-0 border-b-[1px]
          border-b-btnBorder font-bold text-[4.267vw] leading-[6.4vw] text-mainBlack
          flex items-center gap-[3.2vw] normal-case disabled:grayscale disabled:bg-lightGray
          disabled:border-b-[1px] disabled:border-b-lowGray rounded-t-none
        `}
        onClick={clickHandler('google')}
        disabled={terms.some(e => !e) || isLoading}
      >
        {t('pay.payService')}
        <div className="flex relative">
          <img
            src="/images/icons/google-pay.svg"
            alt="apple-pay icon"
            className="w-[12vw] h-[8vw]"
          />
          {terms.some(e => !e) && (
            <div className="absolute top-0 left-0 w-full h-full bg-overlay"></div>
          )}
        </div>
      </button>
      <button
        disabled={terms.some(e => !e) || isLoading}
        className={`btn no-animation hover:bg-phoneOrange bg-phoneOrange w-full border-0 border-b-[1px]
          border-b-btnBorder font-bold text-[4.267vw] leading-[6.4vw] text-mainBlack
          flex items-center gap-[3.8vw] normal-case disabled:grayscale disabled:bg-lightGray
        `}
        onClick={clickHandler('card')}
      >
        {t('pay.payCard')}
        <div className="relative">
          <img src="/images/icons/card.svg" alt="card icon" className="w-[5.333vw] h-[5.333vw]" />
          {terms.some(e => !e) && (
            <div className="absolute top-0 left-0 w-full h-full bg-overlay"></div>
          )}
        </div>
      </button>
    </div>
  );
};
