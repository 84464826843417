import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TOAST_OPTIONS } from '../../../config';
import { useDisclosure } from '../../../hooks/useDisclosure';
import { useLoader } from '../../../hooks/useLoader';
import { useCreateMenuMutation, useGetMenuQuery } from '../../../redux/api/menu';
import { IMenuResponse } from '../../../redux/api/types/menu';
import { useAppSelector } from '../../../redux/hooks';

export const useMenuBody = () => {
  const [search] = useSearchParams();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [categories, setCategories] = useState<IMenuResponse['menu_categories']>([]);
  const { isOpen, close, open } = useDisclosure();
  const { i18n, t } = useTranslation();
  const { id, menuLang, adminId } = useAppSelector(state => state.user);
  const [activeLanguages, setActiveLanguages] = useState<string[]>([]);
  const {
    data,
    refetch,
    isLoading: isLoadingQuery,
    isFetching,
  } = useGetMenuQuery(
    { id, lang: menuLang || i18n.language },
    { skip: !id, refetchOnMountOrArgChange: true }
  );

  const [createMenu, { isLoading, isSuccess }] = useCreateMenuMutation();
  const isLoader = useLoader(isLoadingQuery, isLoading, isFetching);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('foodMenu.toast.menuCreated'), TOAST_OPTIONS);
    }
  }, [isSuccess]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data && searchValue) {
        setCategories(
          data.menu_categories.filter(e => e.name.toLowerCase().includes(searchValue.toLowerCase()))
        );
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    if (data) {
      setActiveLanguages(data.activeLanguages);

      if (!categories.length) {
        setCategories(data.menu_categories.slice((page - 1) * 8, page * 8));
      }
    }
  }, [data]);

  useEffect(() => {
    const urlPage = search.get('page');
    let currentPage = page;
    if (urlPage) {
      setPage(+urlPage);
      currentPage = +urlPage;
    }
    if (data) {
      setCategories(data.menu_categories.slice((currentPage - 1) * 8, currentPage * 8));
    }
  }, [data]);

  const createMenuHandler = () => {
    createMenu(id).then(refetch);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  return {
    createMenuHandler,
    isLoader,
    isOpen,
    close,
    open,
    data,
    refetch,
    t,
    handleSearch,
    searchValue,
    categories,
    setCategories,
    activeLanguages,
    setActiveLanguages,
    adminId,
    id,
    page,
    setPage,
  };
};
