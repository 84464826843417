import { toast } from 'react-toastify';

const configTemplate = {
  devConfig: {
    fetchUrl: 'http://localhost:3001/api',
  },
  prodConfig: {
    fetchUrl: 'https://beezytips.ge/api',
  },
};

const getConfigType = () => {
  return process.env.REACT_APP_NODE_ENV === 'development' ? 'devConfig' : 'prodConfig';
};

const configType = getConfigType();

export const fetchUrl = process.env.REACT_APP_API_URL || configTemplate[configType].fetchUrl;

export const TOAST_OPTIONS = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
};
